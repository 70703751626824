@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.SponsoredRecruiterSearchResult {
  width: 100%;
  .row-container {
    @include custom-spaced-cols(0.5rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .name-col {
    text-align: left;
    font-weight: bold;
    @extend .col-12, .col-lg-4;
  }
  .date-col {
    text-align: left;
    @extend .col-6, .col-lg-4, .text-lg-center;
  }
  .hire-count-col {
    text-align: right;
    @extend .col-6, .col-lg-4;
  }
}
