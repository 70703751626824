@import "../../../scss/_colors";
@import "../../../scss/_layout";

.CandidatesByStatusCountItem {
  @extend .simple-spaced-rows;
  .statusCount {
    text-align: center;
  }
  .countSpan {
    font-weight: bold;
  }
  .statusInformationgroup {
    padding-top: 1em;
    align-items: center;
  }
}
