@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ShareBar {

  // Margin between buttons
  * + * {
    margin-left: 0.3em;
  }
}
