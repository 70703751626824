@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.WhyNeedInsights {
  @extend .big-spaced-rows;

  .why-need-insights-col {
    @extend .double-spaced-rows;
  }

  .title-col {
    font-size: 3em;
    font-weight: 600;
    line-height: 1.2em;
  }

  .text-col {
    font-size: 1.15em;
  }

  .why-need-insights-col {
    @extend .center-vertically, .col-lg-6;
  }
}
