@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.LandingBlock {
  @extend .medium-spaced-rows;

  .title-col {
    font-size: 3em;
    font-weight: bold;
  }

  .subtitle-col {
    font-size: 2em;
  }

  .tagline-div {
    @extend .double-spaced-rows;
    padding-top: 3em;
    text-align: center;
  }

  .tagline-text-col {
    font-weight: bold;
  }

  .tagline-action-col {
  }

  .action-button {
    @extend .btn-secondary;
    font-weight: bold;
    height: 3em;
    width: 12.5em;
    font-size: 1em;
  }

  .how-it-works-step-pic-col {
    text-align: center;
    align-content: center;
  }

  .how-it-works-col-container {
    @extend .medium-spaced-rows;
  }

  .how-it-works-col {
    @extend .col-md-4, .col-sm-12, .col-12;
  }

  .step-col {
    text-align: center;
    align-content: center;
    font-size: 1.2em;
    font-weight: bold;
  }

  .step-description-col {
    text-align: center;
    align-content: center;
    margin-top: 1em;
  }

  .latest-jobs-title-col {
    @extend .jumbo-text-color;
  }

  .latest-jobs-subtitle-col {
    @extend .jumbo-text-color;
  }

  .action-row {
    text-align: center;
    align-content: center;
  }

  .jumbo-text-color {
    color: $maw-white;
  }

  @include media-breakpoint-down(lg) {
    .tagline-div {
      padding: 2em;
    }
    .subtitle-col {
      font-size: 1.5em;
    }
    .step-description-col {
      font-weight: normal;
    }
    .step-img {
      height: 8em;
    }
    .all-recruiters-div {
      padding: 2em;
    }
  }
}
