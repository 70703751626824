@import "../../../scss/_bootstrap-configuration";

.JobSearchResultList {
  align-content: center;
  margin-right: 10%;
  margin-left: 10%;

  .card {
    margin-bottom: 1rem;
  }

  .card-header {
    font-weight: bold;
  }

  .button-row {
    text-align: center;
  }
}

@include media-breakpoint-down(md) {
  .JobSearchResultList {
    margin-right: 0;
    margin-left: 0;
  }
}
