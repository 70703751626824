@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_colors";
@import "~bootstrap/scss/_modal";

.ApplicationForm {
  .label-row {
    color: $label-grey;
    padding-top: 0.5em;
  }

  .form-actions {
    margin-top: 1rem;
    text-align: center;

    > * + * {
      // Space between buttons in a row
      margin-left: 1rem;
    }
  }

  .col-action {
    text-align: center;
  }

  .last-col {
    @extend .spaced-children;
    text-align: center;
  }

  .commission-col {
    background-color: $maw-green;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 0.3em 0.3em 0.3em 0.3em;
    font-weight: normal;
    font-size: 1.5em;
  }

  .commission-title-col {
    font-weight: bold;
  }

  .action-link-row {
    text-align: right;
  }
}

.EmailModal  {
  text-align: center;

  .modal-dialog {
    // Center dialog vertically
    @extend .modal-dialog-centered;
  }

  .modal-content {
    border-color: $success-green;
    border-width: medium;

    .modal-body {
      padding: 2rem;

      // Make some space between elements
      & > * + * {
        margin-top: 2rem;
      }

      .status-icon {
        height: 5rem;
      }
    }
  }

  @extend .simple-spaced-rows;
}
