// Add a standard vertical space between a container's first-level children
.spaced-children {
  > * + * {
    margin-top: 1rem !important;
  }
}

// Add a vertical space between columns of a row but only when they wrap
// Use margin-bottom instead of margin-top so that it does not override
// margin-top when the row is inside a .spaced-children parent.
.wrap-spaced-children {
  $vspace: 1rem;

  margin-bottom: -$vspace;
  > * {
    margin-bottom: $vspace;
  }
}

// Content will be centered vertically
.center-vertically {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

$spaced-button-inline: 0.5em;

// Call this on a container of rows to insert vertical space between rows. When a row has multiple columns and
// they form new rows as they wrap, the same vertical space will be inserted between the new rows.
// If a different vertical space is required between wrapping columns of a particular row, call custom-spaced-cols mixin on that row.
@mixin spaced-rows($vspace) {
  // Use margin-top to add space between rows. It will not conflict with the margin-bottom used by spaced-cols.
  > .row + .row {
    margin-top: $vspace;
  }

  // Use margin-bottom on every row and children columns to control vertical space when columns wrap.
  > .row {
    @include spaced-cols($vspace);
  }
}

// See spaced-rows.
@mixin custom-spaced-cols($vspace) {
  // The extra .row selector is on purpose, it makes the rule more-specific by the mere addition of selectors, so
  // that this rule takes precedence over the rules of spaced-rows mixin.
  &.row {
    @include spaced-cols($vspace);
  }
}

// Do not call this directly, use either spaced-rows or custom-spaced-cols mixins.
@mixin spaced-cols($vspace) {
  // Add a margin-bottom to every column of a row so that they are separated by a vertical space when they wrap
  // on top of each other, but cancel this margin-bottom on the last "row"
  margin-bottom: -$vspace;

  > .col {
    margin-bottom: $vspace;
  }
}

// Insert a standard vertical space between children rows of a container
.simple-spaced-rows {
  @include spaced-rows(1rem);
}

// Insert a standard vertical space between children rows of a container
.double-spaced-rows {
  @include spaced-rows(2rem);
}

// Insert a vertical space three times the standard space, between children rows of a container
.medium-spaced-rows {
  @include spaced-rows(3rem);
}

// Insert a vertical space three times the standard space, between children rows of a container
.big-spaced-rows {
  @include spaced-rows(5rem);
}

