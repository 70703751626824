@import "../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../scss/_layout";

$text-color: $gray-700;
$transition-speed: 0.5s;

.Footer {
  // Make text color a bit lighter than default color so that contrast with active link is stronger

  color: $text-color;
  padding-top: 2em;

  .FooterItem {
    padding-bottom: 0.5em;
    cursor: pointer;
    color: $light-background;
    @extend .FooterItemHoverTransition;
  }
  .FooterItemGroup {
    @extend .col-12, .col-sm-3;
  }

  .footerItem-col{
    @extend .spaced-children;
  }

  .FooterHeaderItem {
    padding-bottom: 1em;
    color: $light-background;
    font-weight: bold;
    font-size: 1.3em;
  }

  .SocialIcon {
    margin-right: 0.5em;
    width: 2em !important;
    height: 2em !important;
    @extend .SocialIconHoverTransition;
  }

  // Transition hover et not hover sur éléments
  .SocialIconHoverTransition {
    &:hover {
      margin-top: -1em;
      transition: $transition-speed;
    }

    &:not(:hover) {
      margin-top: 0;
      transition: $transition-speed;
    }
  }

  .FooterItemHoverTransition {
    &:hover {
      padding-left: 0.7em;
      color: darken($light-background, 50%);
      transition: $transition-speed;
    }

    &:not(:hover) {
      padding-left: 0;
      transition: $transition-speed;
    }
  }
  @include media-breakpoint-down(sm) {
    .FooterItem {
      padding-bottom: 0em;
    }
    .FooterHeaderItem {
      padding-bottom: 0em;
    }

   .footerItem-row{
     margin-bottom: 0.5em;
   }
   .FooterItemGroup {
    margin-top:1.5em;
  }

  }
}
.companySignature {
  padding-top: 1em;
  padding-bottom: 0.5em;
  color: $text-color;
  text-align: center;
  align-content: center;
}