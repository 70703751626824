@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.DashboardBlock {
  @extend .double-spaced-rows;

  .alerts-col {
    @extend .col-sm-12, .col-md-3;
  }

  .button-col {
    @extend .text-end;
  }

  .create-candidate-action {
    font-size: 5em;
  }

  .browse-jobs-label {
    @extend .col-12, .col-lg-auto, .text-center;
  }

  .browse-jobs-Row {
    justify-content: center;
    font-size: 1.3em;
    @extend .wrap-spaced-children;
  }

  .label-action {
    font-size: 1em;
  }

  .create-candidate-button-col {
    text-align: right;
    @extend .col-auto;
  }

  .sub-block {
    @extend .widget-block, .shadow-lg;
  }

  .create-row-container {
    justify-content: end;
  }

  .link-col {
    margin-top: 0.5em;
    @extend .col-auto, .text-lg-start;
  }

  .list-row {
    @extend .wrap-spaced-children;
  }

  .information-col {
    @extend .col-12, .col-lg-4;
  }
}

.employer-reviews-div {
  @extend .double-spaced-rows, .widget-block, .shadow-lg;
}

.recommendation-div-container {
  @extend .double-spaced-rows, .widget-block;
}

.dashboardStatisticalBlocs {
  @extend .simple-spaced-rows;
  justify-content: center;
  .widget-block {
    height: 15em;
  }
  .starsItemCol {
    padding: 0;
  }
  .starsItemRow {
    align-items: center;
  }
}
