@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";


.CandidateSaveApplicationForm {
  .invite {
    font-size: 1.2em;
  }

  @extend .double-spaced-rows;

  .field-container {
    @extend .simple-spaced-rows;
  }
}
