@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "../../../scss/_index";
@import "~bootstrap/scss/_grid";

.JobSort {
  align-items: center;
  @include custom-spaced-cols(0.5rem);
  .job-sort-form-control {
    @extend .filter-form-control;
  }
  .option {
    background-color: $maw-white;
  }
  .job-sort-label-col {
    @extend .col-12, .col-lg-auto;
    padding-right: 0;
  }
  .job-sort-label-row {
    justify-content: center;
  }
}
