@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.CandidateSaveRewardForm {
  @extend .widget-block;

  .title {
    font-weight: bold;
  }
}
