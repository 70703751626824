@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_index";

.SearchBox {
  .input-group {
    // Align input group in the center of the page
    justify-content: center;
  }

  .search-input {
    max-width: 500px;
    // For column widths smaller than max-width, do not occupy 100% because search icon will wrap
    width: 80%;
  }
  // boostrap class for form control
  .form-control {
    @extend .custom-form-control;
  }
}
// boostrap class for icon
.input-group-text {
  @extend .custom-icon;
  background-color: $maw-white;
}
