@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "../../../scss/_header";

.BasePage {

  // To avoid overlap with the fixed header navbar
  padding-top: $expanded-header-height;

  @include media-breakpoint-down(lg) {
    padding-top: $collapsed-header-height;
  }

  // Set min-height of viewport (minus the fixed header) so that footer is pushed to bottom if main contents is
  // too small to fill entire viewport (minus the fixed header)
  .scrollable-content {
    display: flex;
    min-height: calc(100vh - #{$expanded-header-height});

    @include media-breakpoint-down(lg) {
      min-height: calc(100vh - #{$collapsed-header-height});
    }

    flex-direction: column;
    justify-content: space-between;
    .main-container {
      // Add empty space between header and top of contents
      padding-top: 2rem;
      // Add empty space between bottom of contents and footer
      padding-bottom: 10rem;
    }
    .footer-nav{
      background-color: $footer-background;
    }
  }
}
