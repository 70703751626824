@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.JobSearchPane {
  .form-container {
    @extend .double-spaced-rows;
  }
  .action-col {
    @extend .col-12, .text-center;
  }

  .icon {
    background-color: #fff;
    border-top: none;
    border-right: none;
  }
  .input {
    border-top: none;
    border-right: none;
    border-left: none;
  }
  .first-filter-input-col {
    @extend .col-12, .col-sm-6;
  }
  .second-filter-input-col {
    @extend .col-12, .col-sm-6;
  }
}
