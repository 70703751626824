@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_colors";

.InformationDisplay {
  display: inline-block;
  .btn {
    background-color: $light-background;
    border-radius: 5em;
    padding: 0.5em 2em 0.5em 2em;
    font-weight: bold;
    color: $maw-blue;
    border: none;
    &:hover {
      background-color: $light-background;
      color: $maw-blue;
    }
    &:focus {
      background-color: $light-background;
      color: $maw-blue;
    }
  }
  .icon-info {
    margin-bottom: 0.2em;
  }

  .icon-info-col {
    @extend .col-12, .col-md-auto;
  }

  .information-col {
    @extend .col-12, .col-md-auto;
  }
}
