@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";

.InsightsComparisonResultBlock {
  @extend .double-spaced-rows;
  .title-col {
    font-size: 3em;
    font-weight: 600;
    line-height: 1.2em;
  }

  .CompareInsightsPane {
    .form-col-input,
    .form-col-btn {
      @extend .col-4, .col-lg-2;
    }
  }

  .card-title {
    font-weight: 600;
  }

  .card-col {
    @extend .col-xl-6, .col-12;
  }

  .cards-rows-container {
    @include spaced-rows(0.5em);
  }

  .widget-block {
    height: 100%;
  }

  .popularity-comparison-card {
    @extend .widget-block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
