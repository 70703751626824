@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.EmployerLandingBlock {
  @extend .big-spaced-rows;
  .tagline-div {
    background-image: url("../../../img/mouselight.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-color: $maw-blue;
    color: $maw-white;
    padding: 5em;
    @extend .simple-spaced-rows;
  }

  .banner-div {
    margin: 4em 0em 4em 0em;
    @extend .double-spaced-rows;
  }

  .team-picture-col {
    text-align: center;
  }

  .banner-col {
    @extend .col-12, .col-lg-6, .text-center, .text-lg-start;
  }

  .action-row {
    padding-bottom: 1em;
    text-align: center;
    align-content: center;
  }

  .action-button {
    @extend .btn-secondary;
    font-weight: bold;
    height: 3em;
    width: 12.5em;
    font-size: 1em;
  }

  .banner-col {
    @extend .simple-spaced-rows;
  }

  .banner-row-container {
    align-items: center;
  }

  .tagline-text-col {
    line-height: 1.4em;
    font-size: 2em;
    font-weight: bold;
  }

  .job-image {
    width: 100%;
    height: auto;
  }

  .title-col {
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    align-content: center;
  }

  .image-col {
    text-align: center;
  }

  .pricing-col-container {
    @extend .double-spaced-rows;
  }

  @include media-breakpoint-down(lg) {
    .tagline-text-col {
      font-size: 1.5em;
    }
    .banner-div {
      padding: 1em;
    }
    .title-col {
      font-size: 1.5em;
    }
    .subtitle-col {
      font-size: 1em;
    }
    .step-description-col {
      font-weight: normal;
    }

    .tagline-div {
      padding: 2em;
    }
    .action-row {
      padding-top: 0.5em;
    }

    .job-image {
      width: 90%;
      height: auto;
    }
  }
}
