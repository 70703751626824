@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";

.SponsorshipItem {

  @extend .double-spaced-rows;

  .sponsor-stats, .spotter-count {
    font-weight: bold;
  }

  .sponsor-stats, .program-details {
    text-align: center;
  }
}
