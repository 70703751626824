@import "../../scss/_bootstrap-configuration";
@import "../../scss/_colors";
@import "../../scss/_layout";
@import "../../scss/_index";

.PricingPage {
  .pricing-col {
    @extend .double-spaced-rows;
  }

  .pricing-page-col {
    @extend .big-spaced-rows;
  }
  .book-demo-row {
    text-align: center;
    background-color: $maw-blue;
    padding: 5em;
    @extend .medium-spaced-rows;
    background-image: url("../../img/mouselight.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }

  .book-demo-col {
    color: $maw-white;
  }

  .action-button {
    @extend .btn-secondary;
    font-weight: bold;
    height: 3em;
    width: 13em;
    font-size: 1em;
  }

  .action-row {
    text-align: center;
    align-content: center;
  }

  .text-row {
    text-align: justify;
  }

  .story-row {
    text-align: justify;
  }

  .our-story-title {
    text-align: center;
  }

  .book-demo-row-container {
    @extend .simple-spaced-rows;
  }

  @include media-breakpoint-down(lg) {
    .book-demo-row {
      padding: 1.5em;
    }
  }
}
