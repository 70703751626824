@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";

.ResumeDropbox {
  text-align: center;
  border-width: 1px;
  border-color: $maw-blue;
  border-style: dashed;
  outline: none;

  // Make content take full height and width so that the dropzone covers the whole element
  display: flex;
  align-items: stretch;

  &:focus {
    border-width: 4px;
  }

  &.disabled {
    background-color: $input-disabled-bg;
  }

  img {
    height: 5rem;
  }

  .dropZoneContainer {
    // Make content take full height and width so that the dropzone covers the whole element
    display: flex;
    align-items: stretch;
    width: 100%;

    .drop-zone {
      // Dropzone must cover all available space
      width: 100%;
      // Center content
      @extend .center-vertically;
    }
  }

  .dropbox-placeholder-div {
    margin-top: 1em;
  }

  .dropbox-placeholder-title {
    font-weight: bold;
  }

  .under-placeholder {
    font-size: 0.8em;
  }

  .extension-placeholder {
    @extend .under-placeholder;
  }

  .max-size-placeholder {
    @extend .under-placeholder;
  }

  @include media-breakpoint-down(lg) {
    .desktop-only {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .mobile-only {
      display: none;
    }
  }
}
