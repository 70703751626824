@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.PresentationSection {
  .section-title-col {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    align-content: center;
    line-height: 1.4em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  }

  .section-text-col {
    font-size: 1.2em;
    text-align: center;
    align-content: center;
  }
}
