@import "../../../scss/_colors";
@import "../../../scss/_layout";
@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.PricingTable {
  @include custom-spaced-cols(2rem);
  .slot-col {
    @extend .col-md-6, .col-lg-3, .col-xl-3, .col-sm-12, .col-12;
    text-align: center;
    align-items: center;
    border: solid $maw-white;
  }

  @include media-breakpoint-down(md) {
    .slot-col {
      border: solid 1em $maw-white;
    }
  }
}
