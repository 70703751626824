@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.RatingStarsItem {
  .starsColContainer {
    justify-content: space-evenly;
  }
  .starsCol {
    @extend .col-auto;
    padding: 0;
  }
}
