@import "../../../scss/_colors";
@import "../../../scss/_layout";

.TotalRevenueItem {
  @extend .double-spaced-rows;
  .title-col-sub {
    font-size: 3em;
    text-align: center;
    margin-top: 0;
  }
   .revenue-span {
    border-radius: 0.1em;
    background: $maw-green;
    padding-left: 1em;
    padding-right: 1em;
  }
}
