@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_colors";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.ApplicationListItem {
  align-items: center;

  @include custom-spaced-cols(0.5rem);

  .employer-item-col {
    text-align: start;
    @extend .col-lg-3;
  }
  .job-title-col {
    text-align: left;
    font-weight: bold;
    @extend .col-12, .col-lg-3;
  }

  .status-col {
    text-align: center;
    @extend .order-2, .order-lg-1;
  }

  .date-col {
    text-align: end;
    font-size: 0.8em;
    @extend .col-12,
      .col-md-6,
      .col-lg-2,
      .text-start,
      .text-lg-end,
      .order-1,
      .order-lg-2;
  }

  .commission-col {
    text-align: start;
    white-space: nowrap;
    @extend .col-12, .col-lg-1;
  }

  .status-col {
    font-size: 0.7em;
    @extend .simple-spaced-rows;
  }

  .status {
    white-space: nowrap;
  }

  .icon-col {
    @extend .col-auto;
  }

  @include media-breakpoint-up(lg) {
    .icon-col {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    .date-col {
      font-size: 1em;
    }
  }
}
