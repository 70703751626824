@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.ShortListJob {
  @extend .big-spaced-rows;
  .action-row {
    text-align: center;
    align-content: center;
  }

  .result-list-title {
    font-size: 1.5em;

    text-align: center;
    align-content: center;
  }

  .result-list-col {
    @extend .double-spaced-rows, .col-12, .col-sm-6;
    @include custom-spaced-cols(4rem);
  }

  .short-list-title-col {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    align-content: center;
    line-height: 1.4em;
  }

  // Les titres des deux colonnes ne sont pas de la même longueur, alors sur les écrans de taille "lg"
  // il y en a un qui "wrappe" sur deux lignes et l'autre pas. Forcer la cellule à avoir la taille de
  // deux lignes, pour que ce qui suit débute au même endroit, pour les deux colonnes.
  @include media-breakpoint-down(lg) {
    .result-title-col {
      height: (1em * $line-height-base * 2);
    }
  }
}
