.EmployerNotFoundBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;

  .employer-not-found-label {
    text-align: center;
    font-size: 2em;
  }
}
