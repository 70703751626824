@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.CandidateSaveResumeForm {
  @extend .widget-block;
  .row {
    justify-content: center;
    padding-bottom: 0.5em;
  }

  .input-div-container {
    @extend .simple-spaced-rows;
  }

  .dropbox {
    height: 25rem;
  }

  .resume-div {
    @extend .col-12, .col-md-7;
  }

  .input-row-container {
  @extend .justify-content-center;
  }

  .input-col-container {
    @extend .col-12, .col-md-7;
  }

  .input-row-container {
    @extend .justify-content-center;
  }

  .icon-col {
    @extend .col-12, .col-sm-auto, .text-center;
  }


  .linkedIn-icon {
    font-size: 3em;
    color: #0073b1;
  }

  .profile-choice-col {
    text-align: center;
    font-size: 1.2em;
  }

  .indeed-icon-div {
    padding: 0.25em;
  }

  .profile-choice-paragraph {
    padding: 3em;
  }

  .download-col {
    text-align: center;
    font-size: 1.1em;
  }

  .download-col-container {
    @extend .simple-spaced-rows;
  }
}

@include media-breakpoint-down(md) {
  .profile-choice-paragraph {
    padding: 0.5em !important;
  }
}
