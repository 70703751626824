@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.KeywordFilter {
  .submit-col {
    @extend .col-md-auto;
  }
  .input{
    border-width: 0 0 1px 0;
  }

  .icon{
    border-width: 0 0 1px 0;
  }
}
