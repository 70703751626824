@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.JobSearchItemLanding {
  border-radius: 0.5em;
  .job-link {
    text-align: left;
    font-size: 1.4em;
  }

  .job-search-card {
    padding-right: $container-padding-x;
    padding-left: $container-padding-x;
  }

  .job-search-row-container {
    @include custom-spaced-cols(0.5rem);
  }

  .commission-col {
    background-color: $maw-green;
    border-radius: 0.3em 0em 0em 0.3em;
    font-weight: normal;
    font-size: 2em;
  }

  .recommend-col {
    text-align: right;
  }

  .last-col {
    @extend .col-12, .col-lg-4, .spaced-children;
    text-align: center;
  }

  .logo-row {
    align-items: baseline;
  }

  .icon-col {
    @extend .col-auto;
  }

  .section-title {
    font-weight: bold;
  }

  .jobInformation {
    @extend .spaced-children, .col-12, .col-lg-8;
  }

  .commission-title-col{
    font-weight: bold;
  }

  @include media-breakpoint-down(lg) {
    .commission-col {
      border-radius: 0 0 0.2em 0.2em;
    }

    .job-search-card {
      padding-bottom: 0;
    }
  }
}
