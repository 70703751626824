@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_buttons";
@import "~bootstrap/scss/_utilities";
@import "~bootstrap/scss/utilities/_api";
@import "./_colors";

//body
body {
  background-color: $maw-grey;
  // Always display the vertical scrollbar (except on mobile devices) so that content does not move to the left
  // while some other content is loading and the page is not tall enough to have a vertical scrollbar
  overflow-y: scroll;
}

// Buttons
.ActionButtonWithIcon {
  &.btn {
    border-width: 2px;
  }

  @include button-variant(
    $background: white,
    $border: $maw-green,
    $color: $maw-blue,
    $hover-background: $maw-green,
    $hover-color: white
  );
}

//champ de donnee a modifier
.data-field {
  color: darken($light-background, 30%);
}

// Links
.ActionLink,
a {
  text-decoration-thickness: from-font;
}

// Widgets
.widget-block {
  @extend .shadow-sm, .p-4, .m-1, .bg-white, .rounded;
}

//custom input with border button only
.custom-form-control {
  border-top: none;
  border-left: none;
  border-right: none;
}
//custom icon with border button only
.custom-icon {
  border-top: none;
  border-right: none;
}

//filter select form control
.filter-form-control{
  font-size: 0.8em;
  background-color: $dark-background;
  border: none;
  padding: 0.6em;
  border-radius: 5px;
  text-align: center;
}

