@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_index";
@import "../../../scss/_colors";

.JobBlock {
  @extend .big-spaced-rows;

  .logo-col {
    @extend .col-auto;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .Job-container {
    background-color: $maw-white;
    padding: 4em;
    @extend .double-spaced-rows;

    .action-row {
      text-align: center;
    }

    .icon-col-container {
      @extend .simple-spaced-rows, .col-12, .col-md-6;
    }
    .commission-col {
      text-align: center;
      align-content: center;

      @extend .col-12, .col-md-6, .simple-spaced-rows;
    }

    .section-title {
      @extend .title;
    }

    .recommend-button {
      float: right;
    }

    .commission-amount-span {
      background-color: $maw-green;
      padding: 0.1em 0.7em 0.1em 0.7em;
      border-radius: 5px;
    }

    .Skills-col {
      @extend .simple-spaced-rows;
    }

    .commission-amount-col {
      font-size: 2em;
      color: $maw-blue;
    }

    .asterisk-link {

      position: relative;
      display: inline-block;

      > * {
        position: absolute;
        bottom: 0.1em;
      }
    }
  }

  .job-similar-col {
    @extend .double-spaced-rows;
  }

  .job-similar-title {
    @extend .title;
    text-align: center;
  }

  .title {
    font-size: 1.2em;
    font-weight: bold;
  }

  .iconCol {
    @extend .col-auto;
  }

  .archived-message {
    font-size: 1.2em;
    color: $error-red;
    font-weight: bold;
  }
}
