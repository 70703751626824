@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.EmployerHeaderBlock {
  @extend .simple-spaced-rows;

  .employer-header-div {
    @extend .jumbo-text-color;
    @extend .jumbodiv;
  }

  .tagline-col {
    font-size: 1.5em;
    text-align: center;
    align-content: center;
  }

  .title-col {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 2em;
    font-weight: bold;
  }

  .logo-col {
    @extend .col-auto;
  }

  .logo {
    height: 4em;
  }

  .jumbodiv {
    background-color: $maw-blue;
    padding: 2em;
  }

  .jumbo-text-color {
    color: $maw-white;
  }

  .button-col {
    display: flex;
    align-items: center;
    @extend .col-auto;
  }

  .SocialIcon {
    margin-right: 0.5em;
    width: 2em !important;
    height: 2em !important;
  }
}
