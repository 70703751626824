@import "../../scss/_colors";
@import "../../scss/_bootstrap-configuration";
@import "../../scss/_index";
@import "../../scss/_layout";
@import "~bootstrap/scss/_grid";

.AboutUsPage {
  .title-col {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    align-content: center;
    margin-top: 1em;
  }

  .page-container {
    @extend .spaced-children;
  }

  .book-demo-row {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 5em;
    background-color: $maw-blue;
  }

  .book-demo-col {
    color: $maw-white;
    @extend .title-col;
  }

  .action-button {
    @extend .btn-secondary;
    font-weight: bold;
    height: 3em;
    width: 13em;
    font-size: 1em;
  }

  .action-row {
    padding-top: 1em;
    padding-bottom: 2em;
    text-align: center;
    align-content: center;
  }

  .logo-row {
    width: 20em;
    padding-top: 1em;
    padding-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
  }

  .team-picture-col {
    align-content: center;
    text-align: center;
  }

  .team-picture {
    margin-top: 2em;
    margin-bottom: 1em;
    width: 25em;
    height: 20em;
  }

  .text-row {
    text-align: justify;
  }

  .story-row {
    text-align: justify;
  }

  .team-text-col {
    text-align: justify;
  }
  .img-partner {
    margin-top: 3em;
    height: 5em;
    width: auto;
  }
  .img-pme {
    margin-top: 3em;
    height: 5em;
    width: auto;
  }
  .img-cnrc {
    height: 7em;
    width: auto;
    margin-top: 2em;
  }
  .cnrc-col {
    @extend .col-12, .col-md-4;
  }
  .pme-col {
    @extend .col-12, .col-md-4;
  }
  .economie-col {
    @extend .col-12, .col-md-4;
  }

  .partner-logo-row {
    text-align: center;
  }
}
