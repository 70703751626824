@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_index";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.CandidateSaveInformationForm {
  padding: 5em !important;
  @extend .double-spaced-rows, .widget-block;
  .row {
    justify-content: center;
  }

  .information_invite_row {
    text-align: center;
    font-size: 1.3em;
  }

  .candidates-invite-action-link {
    font-size: 1.3em;
  }

  .application-count-row {
    text-align: center;
    font-size: 1.2em;
  }

  .input-row {
    margin-bottom: 0.5em;
    @extend .row-container;
  }

  .input-col {
    @extend .col-container;
  }

  .indication-row {
    @extend .row-container;
  }

  .indication-col {
    @extend .col-container;
  }

  .row-container {
    @extend .justify-content-center;
  }

  .col-container {
    @extend .col-12, .col-md-6;
  }
}

@include media-breakpoint-down(md) {
  .CandidateSaveInformationForm {
    padding: 1em !important;
  }
}
