.CandidatesList {

  .form-actions {
    margin-top: 1rem;
    text-align: center;

    > * + * {
      // Space between buttons in a row
      margin-left: 1rem;
    }
  }
}
