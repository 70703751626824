@import "../../../scss/_colors";
@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";

.SponsoredInviteBlock {

  @extend .simple-spaced-rows;
  text-align: center;

  .sponsor-link-container {
    position: relative;
  }

  .sponsor-link {
    font-weight: bold;
  }

  .copy-action, .copied-label {
    margin-left: 0.5rem;
  }

  .copied-label {
    color: $success-green;
    visibility: hidden;
    position: absolute;

    &.active {
      visibility: visible;
    }
  }

}
