@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.InlineEditBase {
  .view {
    // Center value and actions vertically
    display: flex;
    align-items: center;

    .no-value {
      font-style: italic;
    }

    .action {
      color: $tertiary;
    }
  }

  .edit {
    .button-col {
      @extend .col-auto;
    }
  }

  // Surround view value with box if multiline
  &.multiline-view {
    .view {
      .value {
        border-style: solid;
        border-width: $border-width;
        border-color: $card-border-color;

        // Make the border take the whole width
        width: 100%;

        .ActionDiv {
          // Display the value at the exact same place as the placeholder
          padding: $input-btn-padding-y $input-btn-padding-x;

          // Make the whole space clickable, not only the text
          width: 100%;

          min-height: 6rem;
        }
      }
    }
  }

  .updateIcon {
    font-size: 1.2em;
    margin-bottom: 0.2em;
  }
}
