@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.InsightsResultBlock {
  @extend .double-spaced-rows;

  .title-span {
    font-size: 3em;
    font-weight: 600;
    line-height: 1.2em;
  }

  .change-link-sapn {
    font-size: 1.15em;
    margin-left: 0.5em;
  }

  .comparaison-col {
    @include spaced-rows(0.5em);
    font-size: 1.15em;
  }

  .card-col {
    @extend .col-xl-6, .col-12;
  }

  .widget-block {
    height: 100%;
  }

  .CompareWithPane {
    .form-col-input {
      @extend .col-8, .col-lg-5, .col-xxl-3;
    }

    .form-col-btn {
      @extend .col-4, .col-lg-2;
    }
  }

  .card-title {
    font-weight: 600;
  }
}
